
<style lang="scss">
@import "@/assets/sass/pages/settings.scss";
</style>
<template>
  <div class="card card-custom gutter-b">
    <div class="card-body">
      <s-company></s-company>
      <s-atte-time-off></s-atte-time-off>
      <s-general></s-general>
      <s-payroll></s-payroll>
    </div>
  </div>
</template>

<script>
import Company from "./company";
import Payroll from "./payroll";
import AttendanceAndTimeOff from "./attendanceandtimeoff";
import General from "./general";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
export default {
  components: {
    "s-company": Company,
    "s-atte-time-off": AttendanceAndTimeOff,
    "s-general": General,
    "s-payroll":Payroll
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Settings" }]);
  },
};
</script>

